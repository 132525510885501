<template>
    <div>
    <div class="support_content">
        <div style="padding-bottom: 100px">
            <div class="quality_doctor_banner">
                <div class="back">
                </div>
                <div class="back1">

                </div>
<!--                <div class="cloud-left">-->
<!--                    <img src="../../../assets/img/support/cloud_left.png"/>-->
<!--                </div>-->
<!--                <div class="cloud_right_top">-->
<!--                    <img src="../../../assets/img/support/cloud_right_top.png"/>-->
<!--                </div>-->
<!--                <div class="cloud_right_bottom">-->
<!--                    <img src="../../../assets/img/support/cloud_right_bottom.png"/>-->
<!--                </div>-->
            </div>
            <div style="height: 450px;margin-top: -450px;">
                <el-row>
                    <el-col :span="16">
<!--                        <div style="position:absolute;left:180px;top: -120px;z-index:80;">-->
<!--                            <img src="../../../assets/img/support/背景.png" style="width: 600px; opacity: 0.5"/>-->
<!--                        </div>-->
                        <el-row class="wi100" type="flex" style="z-index: 10">
                            <el-col :span="24" justify="space-around">
                                <div class="qdHeader" style="font-size: 3rem;color: white;opacity: 1;text-shadow: 1px 1px 1px black;">
                                    <span style="opacity: 1">
                                        数据安全共享平台
                                    </span>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row class="wi100" type="flex" style="z-index: 10">
                            <el-col :span="24" justify="space-around">
                                <div class="qdHeader" style="font-size: 4rem;color: #ff7105;text-shadow: 1px 1px 1px #000000;">招募全国优秀服务商入驻</div>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="8">
                        <div style="position: absolute;z-index: 10">
                            <div style="text-align: left;margin-bottom: 20px;margin-top: -40px;margin-left: -80px;">
                                <img style="width: 400px;height: 100%;object-fit: contain;" :src="require('../../../assets/img/support/QRCode.png')"/>
<!--                                <img style="width: 400px;height: 100%;object-fit: contain;" :src="require('../../../assets/img/support/cqc.jpg')"/>-->
                            </div>
                            <div style="text-align: left;margin-top: -30px;margin-left: 55px;">
                                <el-button type="primary">
                                    扫描二维码入驻
                                </el-button>
                            </div>
                        </div>

                    </el-col>
                </el-row>
            </div>
            <div class="part2 animate__animated animate__fadeInUp" style="margin-top: 40px">
                <div style="width: 70%;margin: 0 auto">
                    <div class="p-title uf uf-ac uf-pc">
                        <span style="font-weight: bold;">合作优势</span>
                    </div>
                    <el-row>
                        <el-col :span="8">
                            <div style="text-align: center">
                                <img style="width: 260px;height: 100%;" :src="require('../../../assets/img/support/security.png')"/>
                            </div>
                            <div style="text-align: center;font-weight: bold;font-size: 20px;margin-top: 15px">
                                国家平台有保障
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div style="text-align: center">
                                <img style="width: 260px;height: 100%;" :src="require('../../../assets/img/support/order.png')"/>
                            </div>
                            <div style="text-align: center;font-weight: bold;font-size: 20px;margin-top: 15px">
                                急速合作海量客单
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div style="text-align: center">
                                <img style="width: 260px;height: 100%;" :src="require('../../../assets/img/support/traffic.png')"/>
                            </div>
                            <div style="text-align: center;font-weight: bold;font-size: 20px;margin-top: 15px">
                                入驻即享百万流量
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="part2 animate__animated animate__fadeInUp">
                <div style="width: 60%;margin: 0 auto">
                    <div class="p-title uf uf-ac uf-pc">
                        <span style="font-weight: bold;">平台优势</span>
                    </div>
                    <div class="li-wrap uf fwrap-w">
                        <div class="li uf">
                            <i class="iconfont icon-neirong"></i>
                            <div>
                                <div class="h">数据分析</div>
                                <div class="dec mt-2">拥有企业大数据、企业画像、多平台接入，兼容各种复杂的系统架构，高效数据处理分析。</div>
                            </div>
                        </div>
                        <div class="li uf">
                            <i class="iconfont icon-suanfa"></i>
                            <div>
                                <div class="h">服务监察</div>
                                <div class="dec mt-2">从服务需求沟通，到服务制定、下单、服务、回访、评价等全流程监控，再通过运维大数据分析辅助决策，全方位为客户保障服务。</div>
                            </div>
                        </div>
                        <div class="li uf">
                            <i class="iconfont icon-share"></i>
                            <div>
                                <div class="h">智能推荐</div>
                                <div class="dec mt-2">通过企业大数据，以融合知识图谱和协同过滤的混合推荐模型为基础，分析企业个性化需求。</div>
                            </div>
                        </div>
                        <div class="li uf">
                            <i class="iconfont icon-gaoxiao"></i>
                            <div>
                                <div class="h">高效运营</div>
                                <div class="dec mt-2">通过智能化服务体系，帮助企业快速实现服务需求所需、所想、所要，从而加速企业服务决策自动化实现，最大化提高运营效率。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="part5">
                    <div class="wrap">
                        <div class="p-title uf uf-ac uf-pc">
                            <span style="font-weight: bold;">三大服务模式</span>
                        </div>
                        <div class="li-wrap uf uf-pa">
                            <div class="li">
                                <div class="li-tit">自助式</div>
                                <div class="dec">
                                    <p>快捷检索服务</p>
                                    <p>私人订制需求</p>
                                    <p>急速接单</p>
                                    <p>全流程跟踪</p>
                                </div>
                            </div>

                            <div class="li">
                                <div class="li-tit">推荐式</div>
                                <div class="dec">
                                    <p>汇聚企业特征</p>
                                    <p>采集企业服务</p>
                                    <p>分析企业需求</p>
                                    <p>自动化推荐</p>
                                </div>
<!--                                <a href="/#/recommend"><el-button type="primary">去使用</el-button></a>-->
                            </div>
                            <div class="li">
                                <div class="li-tit">一站式</div>
                                <div class="dec">
                                    <p>分析市场动态</p>
                                    <p>专家级定制</p>
                                    <p>7*24h专属服</p>
                                    <p>企业级集成服务</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part3 animate__animated animate__fadeInUp">
                <div style="width: 80%;margin: 0 auto">
                    <div class="p-title uf uf-ac uf-pc">
                        <span style="font-weight: bold;">八大服务</span>
                    </div>

                    <div class="li-wrap uf fwrap-w">
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon01.png')" alt="">
                                <div class="li-tit">检验检测</div>
                            </div>
                            <div class="hover-show">
                                用指定的方法检验测试某种物体（气体、液体、固体）指定的技术性能指标。适用于各种行业范畴的质量评定，如：土木建筑工程、水利、食品、化学、环境、机械、机器等等。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon02.png')" alt="">
                                <div class="li-tit">标准制定</div>
                            </div>
                            <div class="hover-show">
                                制定一项新标准，是指制定过去没有而现在需要进行制定的标准。它是根据生产发展的需要和科学技术发展的需要及其水平来制定的，因而它反映了当前的生产技术水平。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon03.png')" alt="">
                                <div class="li-tit">质量管理</div>
                            </div>
                            <div class="hover-show">
                                确定质量方针、目标和职责，并通过质量体系中的质量策划、控制、保证和改进来使其实现的全部活动。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon04.png')" alt="">
                                <div class="li-tit">产品安全</div>
                            </div>
                            <div class="hover-show">
                                产品在使用、储运、销售等过程中，保障人体健康和人身、财产安全免受伤害或损失的能力。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon05.png')" alt="">
                                <div class="li-tit">信用评估</div>
                            </div>
                            <div class="hover-show">
                                信用评估机构对征集到的企业信用信息，依据一定指标进行信用等级评定的活动。信用评级作为一个完整的体系，包括信用评级的要素和指标、信用评级的等级和标准、信用评级的方法和模型等方面的内容。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon06.png')" alt="">
                                <div class="li-tit">品牌评价</div>
                            </div>
                            <div class="hover-show">
                                品牌评价的成果与品牌资产评估结果相结合，可以更完整地体现品牌的“内在价值”和“交易价值”，解决了一般只采用无形资产评估方法评估品牌价值的不足之处。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon07.png')" alt="">
                                <div class="li-tit">质量诊断</div>
                            </div>
                            <div class="hover-show">
                                质量诊断，过程或质量管理工作进行诊察，以判定其产品或服务质量是否满足规定要求，或其质量管理工作是否适当、有效、查明发生存在问题的原因，并进而指出改进和提高方向、途径和措施的全部活动。
                            </div>
                        </div>
                        <div class="li">
                            <div class="hover-hide uf uf-ver uf-pc uf-ac">
                                <img :src="require('../../../assets/img/index/function_icon08.png')" alt="">
                                <div class="li-tit">工商/产权</div>
                            </div>
                            <div class="hover-show">
                                提供一对一的工商服务和知识产权服务
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part2 animate__animated animate__fadeInUp" style="margin-top: 40px">
                <div style="width: 60%;margin: 0 auto">
                    <div class="p-title uf uf-ac uf-pc">
                        <span style="font-weight: bold;">400+服务商已入驻平台</span>
                    </div>
                    <el-row type="flex" justify="center">
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                  <img :src="require('../../../assets/img/support/cqc.jpg')" style="height: 130px"/>
                                </el-card>
                            </div>

                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <icon-svg name="gsblogo" style="font-size: 180px"></icon-svg>
<!--                                    <img src="../../../assets/img/support/support_icon2.png" style="height: 80px"/>-->
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <img :src="require('../../../assets/img/support/top.jpg')" style="height: 135px"/>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <img :src="require('../../../assets/img/support/dby.jpg')" style="height: 160px"/>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row type="flex" justify="center" style="margin-top: 10px">
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <img :src="require('../../../assets/img/support/zgg.jpg')" style="height: 100px"/>
                                </el-card>
                            </div>

                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <img :src="require('../../../assets/img/support/yiyun.png')" style="height: 60px"/>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <img :src="require('../../../assets/img/support/znfd.jpg')" style="height: 100px"/>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 135px;display:flex;justify-content: center;align-items: center;">
                                    <img :src="require('../../../assets/img/support/znkj.jpg')" style="height: 180px;margin-top: 20px"/>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="part2 animate__animated animate__fadeInUp" style="margin-top: 40px">
                <div style="width: 60%;margin: 0 auto">
                    <div class="p-title uf uf-ac uf-pc">
                        <span style="font-weight: bold;">四步轻松成为合作服务商</span>
                    </div>
                    <el-row type="flex" justify="center">
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 310px;display:flex;justify-content: center;align-items: center;">
                                    <div class="circle">
                                        <img :src="require('../../../assets/img/support/adduser1.png')" style="width: 100px"/>
                                    </div>

                                    <div style="margin-top: 60px;text-align: center">
                                        <span style="font-weight: bold;font-size: 18px;">1、注册账号</span>
                                    </div>
                                </el-card>
                            </div>

                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 310px;display:flex;justify-content: center;align-items: center;">
                                    <div class="circle">
                                        <img :src="require('../../../assets/img/support/contract.png')" style="width: 100px"/>
                                    </div>

                                    <div style="margin-top: 60px;text-align: center">
                                        <span style="font-weight: bold;font-size: 18px;">2、签订协议</span>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 310px;display:flex;justify-content: center;align-items: center;">
                                    <div class="circle">
                                        <img :src="require('../../../assets/img/support/product.png')" style="width: 100px"/>
                                    </div>

                                    <div style="margin-top: 60px;text-align: center">
                                        <span style="font-weight: bold;font-size: 18px;">3、产品上架</span>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div style="display:flex;justify-content: center;align-items: center;">
                                <el-card style="width: 230px;height: 310px;display:flex;justify-content: center;align-items: center;">
                                    <div class="circle">
                                        <img :src="require('../../../assets/img/support/settlement.png')" style="width: 100px"/>
                                    </div>

                                    <div style="margin-top: 60px;text-align: center">
                                        <span style="font-weight: bold;font-size: 18px;">4、产品结算</span>
                                    </div>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div style="width: 40%;margin: 0 auto;margin-top: 80px;margin-bottom: -60px">
                <div class="p-title uf uf-ac uf-pc">
                    <span style="font-weight: bold;">填写信息或者扫描二维码入驻</span>
                </div>
<!--                <el-row :gutter="40">-->
<!--                    <el-col :span="24">-->
                        <div style="margin-top: 50px;">
                            <el-form ref="form" :model="supportForm" :rules="dataRule" label-width="80px" style="margin-bottom: 45px">
                                <div style="width: 60%;margin: 0 auto">

                                        <el-form-item label="联系人" prop="name">
                                            <el-input v-model="supportForm.name"  placeholder="联系人"></el-input>
                                        </el-form-item>

                                </div>
                                <div style="width: 60%;margin: 0 auto">
                                        <el-form-item label="手机号" prop="mobile">
                                            <el-input v-model="supportForm.mobile"  placeholder="手机号"></el-input>
                                        </el-form-item>
                                </div>
<!--                                <el-form-item label="公司名称" prop="enterpriseName">-->
<!--                                    <el-input v-model="supportForm.enterpriseName" placeholder="公司名称"></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="经营范围" prop="businessScope">-->
<!--                                    <el-input v-model="supportForm.businessScope" type="textarea" placeholder="经营范围"-->
<!--                                              :rows="3"></el-input>-->
<!--                                </el-form-item>-->
                            </el-form>
                        </div>

                        <div style="text-align: center;margin-bottom: -20px;margin-top: 20px">
                            <el-button type="primary" @click="addSupport"
                                       :loading="loading">
                                点击入驻
                            </el-button>
                        </div>
<!--                    </el-col>-->
<!--                    <el-col :span="2">-->
<!--                        <div style="margin-top: -10px">-->
<!--                            <div>-->
<!--                                <img style="width: 100px;" src="../../../assets/img/support/或.png"/>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <span style="font-size: 20px;font-weight: bold;margin-left: 40px">或</span>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <img style="width: 100px;height: 200px" src="../../../assets/img/support/或.png"/>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </el-col>-->
<!--                    <el-col :span="8">-->
<!--                        <div style="margin-left: 76px">-->
<!--                            <img style="width: 260px;" src="../../../assets/img/support/平台二维码.jpg"/>-->
<!--                        </div>-->
<!--&lt;!&ndash;                        <div style="text-align: center;margin-bottom: -20px;margin-top: 40px;margin-left: 100px">&ndash;&gt;-->
<!--&lt;!&ndash;                            <el-button type="primary">&ndash;&gt;-->
<!--&lt;!&ndash;                                或扫描二维码入驻&ndash;&gt;-->
<!--&lt;!&ndash;                            </el-button>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    </el-col>-->
<!--                </el-row>-->
            </div>
        </div>
    </div>
        <div style="width: 100%;height: 380px;background: #2A82E4;">
            <div style="margin: 0 auto">
                <div style="height: 300px;width: 60%;margin: 0 auto">
                    <el-row>
                        <el-col :span="16">
                            <el-row>
                                <el-col :span="8">
                                    <el-row>
                                        <div style="margin-top: 50px">
                                            <img style="width: 60px;float: left" :src="require('../../../assets/img/support/you.png')"/>
                                            <span style="font-weight: bold;font-size: 18px;color: white;float: left;margin-top: 20px;margin-left: 20px">优选产品</span>
                                        </div>
                                    </el-row>
                                    <el-row>
                                        <div style="margin-top: 30px">
                                            <img style="width: 60px;float: left" :src="require('../../../assets/img/support/you.png')"/>
                                            <span style="font-weight: bold;font-size: 18px;color: white;float: left;margin-top: 20px;margin-left: 20px">优选服务</span>
                                        </div>
                                    </el-row>
                                    <el-row>
                                        <div style="margin-top: 30px">
                                            <img style="width: 60px;float: left" :src="require('../../../assets/img/support/you.png')"/>
                                            <span style="font-weight: bold;font-size: 18px;color: white;float: left;margin-top: 20px;margin-left: 20px">优选商家</span>
                                        </div>
                                    </el-row>
                                </el-col>
                                <el-col :span="8">
                                    <div style="margin-left: 30px;">
                                        <div style="margin-top: 67px;">
                                            <span style="font-weight: bold;font-size: 20px;color: white;">服务模式</span>
                                        </div>
                                        <div style="margin-top: 40px;cursor: pointer" @click="$router.push({name: 'index'})">
                                            <span style="font-size: 18px;color: white;margin-left: 20px">自助式</span>
                                        </div>
                                        <div style="margin-top: 40px;cursor: pointer" @click="$router.push({name: 'recommend'})">
                                            <span style="font-size: 18px;color: white;margin-left: 20px">推荐式</span>
                                        </div>
                                        <div style="margin-top: 40px;cursor: pointer" @click="$router.push({name: 'quality-doctor'})">
                                            <span style="font-size: 18px;color: white;margin-left: 20px">一站式</span>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div style="margin-top: 67px;">
                                        <span style="font-weight: bold;font-size: 20px;color: white;">常用链接</span>
                                    </div>
                                    <div style="margin-top: 40px;cursor: pointer" @click="$router.push({name: 'login'})">
                                        <span style="font-size: 18px;color: white;margin-left: 10px">用户登录</span>
                                    </div>
                                    <div style="margin-top: 40px;cursor: pointer" @click="toLogin">
                                        <span style="font-size: 18px;color: white;margin-left: 10px">服务商登录</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="8">
                            <div style="display:flex;justify-content: center;align-items: center;margin-top: 40px;margin-right: 120px">
                                <img style="width: 230px;" :src="require('../../../assets/img/support/qrcode.jpg')"/>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <el-divider></el-divider>
                <div class="f12 tac op-08 wi100" style="color: white;font-size: 16px;margin-top: -10px">
                    © 2019-2022 服务型制造公共服务平台 <a style="color:#FFF; text-decoration:none" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备20007970号-2</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile} from '../../../utils/validate'

    export default {
        name: 'quality-doctor',
        components: {
        },
        data() {
            const checkMobile = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请输入手机号码'))
                } else if (!isMobile(value)) {
                    return callback(
                        new Error('手机号码格式不正确')
                    )
                } else {
                    callback()
                }
            }
            return {
                loading: false,
                supportForm: {
                    name: '',
                    mobile: ''
                },
                dataRule: {
                    name: [
                        {required: true, message: '联系人不能为空', trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true,  validator: checkMobile, trigger: 'change' }
                    ]
                },
                startVal: 0,
                endVal: 412
            }
        },
        created() {
            this.getSupportCount()
        },
        methods: {
            addSupport() {
                this.$refs['form'].validate(valid => {
                    if (valid && !this.loading) {
                        this.loading = true
                        this.$http({
                            url: this.$http.adornUrl(this.$api.SYS.USER.SAVE_SUPPORT),
                            method: 'post',
                            data: this.$http.adornData({
                                'name': this.supportForm.name,
                                'mobile': this.supportForm.mobile
                            })
                        }).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$confirm('服务商账户注册成功，是否去完善信息？', '提示',{
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                }).then(()=>{
                                    window.open('http://console.smartquality.cn/#/login')
                                })
                            }
                        })
                    }
                })
            },
            getSupportCount() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.U.SUPPORT.COUNT),
                    method: 'get'
                }).then(({data}) => {
                    if (data && data.code === 0) {
                      this.endVal = data.count
                    }
                })
            },
            toRegister() {
                window.open('http://console.smartquality.cn/#/register')
            },
            toLogin() {
                window.open('http://console.smartquality.cn/#/login')
            }
        }
    }
</script>

<style scoped>
    .support_content {
    }
    .support_footer {
        height: 100px;
        width: 100%;
        position: fixed;
        bottom: 0;
        background-color: black;
        opacity: 0.8;
        display: flex;
        justify-content:center;
        align-items:Center;
    }
    /*.quality_doctor_banner {*/
    /*    width: 100%;*/
    /*    height: 550px;*/
    /*    background: linear-gradient(200deg,#224c9a,#3a80c9 50%,#56a1e4);*/
    /*    !*background-color: #BDD6F2;*!*/
    /*    background-size: cover;*/
    /*    background-position: center center;*/
    /*} */
    .quality_doctor_banner {
        width: 100%;
        height: 550px;
        background-color: #96bcff;
        background-image: linear-gradient(180deg,#96bcff,#e7f1ff);

        /*background-color: #BDD6F2;*/
        background-size: cover;
        background-position: center center;
    }
    .back1{
        width: 100%;
        height: 550px;
        position: absolute;
        z-index: 9;
        background-size: cover;
        background-position: center center;
        background: url("../../../assets/img/support/banner.png");
        opacity: 0.2;
        top: 140px;
    }
    .back {
        background-size: 100% 570px;
        width: 100%;
        height: 570px;
        /*opacity: 0.1;*/

    }

    .qdHeader {
        padding-top: 80px;
        text-align: center;
        font-weight: bold;
        opacity: 1;
    }
    .quality_doctor_banner2 {
        width: 100%;
        height: 120px;
        background-color: #ff7105;
        background-size: cover;
        background-position: center center;
        display: flex;
        justify-content:center;
        align-items:Center;
        font-size: 35px;
        color: white;
    }
    .button {
        margin-left: 200px;
        font-size: 25px;
        font-weight: bold;
        color: white;
        background-color: #ff7105;
        border: 0;
        padding: 8px;
        border-radius: 5px;
    }
    .button:hover {
        cursor: pointer;
        background-color: #ff4208;
    }

    .part5 {
        width: 60%;
        margin: 0 auto;
        padding: 80px 0;
    }
    .part5 .li {
        width: 25%;
        padding-bottom: 30px;
        text-align: center;
        border-radius: 6px;
        box-shadow: 0 0 16px rgba(0,0,0,.1);
        transition: all .4s ease;
        transform: scale(1,1);
    }

    .part5 .li .li-tit{
        font-size: 28px;
        height: 52px;
        position: relative;
        margin-top: 36px;
    }
    .part5 .li .li-tit:before{
        position: absolute;
        content: '';
        width: 70px;
        bottom: 0;
        height: 3px;
        background: #ff4208;
        left: 50%;
        transform: translateX(-50%);
    }
    .part5 .li .dec {
        min-height: 200px;
        padding-top: 20px;
        opacity: .8;

    }
    .part5 .li p{
        font-size: 16px;
    }
    .p-title{
        font-size: 26px;
        margin-bottom: 40px;
    }
    .p-title span{
        font-size: 26px;
        margin: 0 20px;
    }
    .part3 {
        padding: 80px 0;
        width: 100%;
        height: 760px;
        background: url("../../../../public/img/index-bg.jpg");

    }

    .part3 .p-title {
        color: #e1e1e1;
    }
    .part3 .li-wrap {
        color: white;
    }
    .part3 .li .iconfont {
        font-size: 82px;
    }
    .part3 .li .iconfont {
        font-size: 82px;
    }
    .part3 .li {
        position: relative;
        width: 25%;
        text-align: center;
        border: 1px solid #3a3a3a;
        margin-right: -1px;
        margin-bottom: -1px;
        box-sizing: border-box;
        padding: 60px 0;
        height: 255px;
        overflow: hidden;
    }

    .part3 .li > div {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .5s ease;
    }
    .part3 .li .hover-show {
        top: 100%;
        transition: all .5s ease;
        color: #f6f6f6;
        line-height: 28px;
        text-align: justify;
        padding: 20px;
        font-size: 14px;
        background: rgba(255,255,255,.1);

    }
    .part3 .li .hover-hide {
        top: 0;
        transition: all .5s ease;
    }
    .part3 .li:hover .hover-show {
        top: 0;
        transition: all .5s ease;
    }
    .part3 .li:hover > .hover-hide {
        top: -100%;
        transition: all .5s ease;

    }
    .part3 .li-tit {
        font-size: 18px;
        margin-top: 20px;
    }
    .part2 {
        padding: 40px 0;
    }
    .p-title{
        font-size: 26px;
        margin-bottom: 40px;
    }
    .p-title span{
        font-size: 26px;
        margin: 0 20px;
    }
    .part2 .h{
        font-size: 26px;

    }
    .part2 .li .iconfont {
        font-size: 36px;
        margin-right: 20px;
    }
    .part2 .li {
        padding: 52px;
        width: 50%;
        border: 1px solid #e2e2e2;
        position: relative;
        cursor: pointer;
    }
    .part2 .li:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        background-color: #ff4208;
        background-image: linear-gradient(to right, #ff7105, #ff4208);
        transition: all .5s ease;
    }
    .part2 .li:hover:before {
        width: 100%
    }
    .part2 .li:before {
        content: '';
        display: block;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        background: linear-gradient(#ff7105, #ff4208);
    }
    .part2 .li:nth-child(1),.part2 .li:nth-child(3) {
        margin-right: -1px;
    }
    .part2 .li:nth-child(3),.part2 .li:nth-child(4) {
        margin-top: -1px;
    }
    .part2 .li:nth-child(3) {
        border-top: 0;
    }
    .part2 .li:nth-child(4) {
        border-left: 0;
    }
    .part2 .dec {
        font-size: 14px;
        opacity: .6;
    }

    .el-header {
        box-shadow: 1px 0 5px rgba(0, 0, 0, .1);
        z-index: 999;
        background: #ffffff;
    }

    .header .el-menu--horizontal .el-submenu .el-submenu__title, .header .el-submenu__title {
        font-size: 16px;
        color: #333333;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    }

    .el-menu.el-menu--horizontal, .el-menu--horizontal > .el-menu-item {
        border-bottom: none;
    }

    .el-menu--horizontal > .el-menu-item {
        font-size: 16px;
        color: #333333;
    }

    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
        background: rgba(64, 158, 255, .1);
        color: #409EFF;
    }

    .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        outline: 0;
        color: #409EFF;
        background: rgba(64, 158, 255, .1);
    }

    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        /* outline: 0; */
        /* color: #303133; */
    }

    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: none;
        /*background: rgba(64, 158, 255, .1);*/
        color: #409EFF;
    }

    .el-dialog__wrapper .logo-img {
        width: 40px;
    }
    .text-space {
        letter-spacing: 14px;
        margin-right: 10px;
        margin-left: 10px;
    }
    @media only screen and (max-width: 500px) {
        .text-space {
            letter-spacing: 8px;
            margin-right: 5px;
            margin-left: 5px;
        }
    }
    .cloud-left {
        position: absolute;
        left: 0;
        top: 460px;
        z-index: 9;
        width: 305px;
        height: 194px;
        margin: auto;
    }
    .cloud_right_top {
        position: absolute;
        left: 0;
        top: 100px;
        right: 800px;
        z-index: 9;
        width: 35px;
        height: 194px;
        margin: auto;
    }
    .cloud_right_bottom {
        position: absolute;
        left: 0;
        top: 300px;
        right: 10px;
        z-index: 9;
        width: 35px;
        height: 194px;
        margin: auto;
    }
    .circle {
        background: #409EFF;
        border-radius: 50%;
        height: 10em;
        width: 10em;
        display:flex;
        justify-content: center;
        align-items: center;
    }
</style>
